import { handleQueryResolve } from '../utils';

export default function (firstDrug, secondDrug, range, startDate, endDate) {
  const where = []
  const attrs = []
  if (firstDrug) {
    this.first = `GenericName LIKE '%${firstDrug}%'`
  }
  if (secondDrug) {
    this.second = `GenericName LIKE '%${secondDrug}%'`
  }
  if (range) {
    this.months = `Rx.RxDate > DATEADD(month, -${range}, GetDate())`
  }
  if (startDate) {
    // where.push(`Rx.RxDate > '${startDate}'`)
  }
  if (endDate) {
    // where.push(`Rx.RxDate < '${endDate}'`)
  }

  const query = `
  SELECT DISTINCT
  ROUND(sum(QtyDispense),0) as totalUnits,
  ROUND(count(PatientID),0) as totalPatients,
  ROUND(count(DoctorID),0) as totalDoctors
  FROM Fillware.dbo.Rx
WHERE  RxDate >= DATEADD(DAY, -30, GetDate())

        `
  // console.log(where)
  // console.log(query)

  return this.query(query, attrs).then(handleQueryResolve)
}
